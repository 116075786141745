<template>
<div>
	<div class="result-container">
		<article class="stresscheck-res">
			<h1 class="stresscheck-res__header">
				<img src="@/assets/img/stresscheck-diagnosis/title.png" alt="1分で診断! 医師監修 今の自分がわかるストレスチェック">
				<p><span>presented by</span><i class="stresscheck-res__header--icon sprite_stress_1 logo" /></p>
			</h1>

			<!-- 結果 -->
			<section class="stresscheck-res__result">
				<h2 class="stresscheck-res__result--level">
					<p :class="computedResultItem.level === 0 ? 'is-failed' : ''">{{ computedResultItem.title }}</p>
				</h2>
				<img v-if="computedResultItem.level !== 0" class="stresscheck-res__result--img" :src="computedResultItem.src" :alt="`level_${computedResultItem.level}`">
				<p v-if="computedResultItem.level !== 0" class="stresscheck-res__result--text" v-html="computedResultItem.text" />
			</section>
			<!-- /結果 -->

			<!-- 監修者 -->
			<section class="stresscheck-res__supervisor">
				<h2 class="stresscheck-res__supervisor--title">
					<i class="sprite_stress_2 supervisor" />
					<span><span class="is-large">監修</span><br>アイさくらクリニック<br><span class="is-large">木村昌幹 先生</span></span>
				</h2>
				<p class="stresscheck-res__supervisor--text">医師／厚生労働省認定:精神保健指定医／日本精神神経学会:専門医／日本精神神経学会:認知症診療医／日本心身医学会:&thinsp;専門医／日本医師会:認定産業医</p>
			</section>
			<!-- /監修者 -->
		</article>
	</div>
	<div class="outro-container">
		<article class="stresscheck-res">
			<!-- アウトロ -->
			<section class="stresscheck-res__outro">
				<h2 class="stresscheck-res__outro--title">自律神経が乱れると、気圧や温度の変化で体調不良になる「気象病」の症状も出やすくなります。頭痛ーるは、不調の原因となる「気圧の変化」をお知らせします。</h2>
				<div class="growthhack-f__caution">
					<p class="growthhack-f__caution--right">低気圧で体調崩すかも。早めに用事を済ませよう！</p>
					<p class="growthhack-f__caution--left">痛みが出る前に薬を準備しておこう！</p>
				</div>
				<div class="growthhack-f__with-zutool">
					<p class="growthhack-f__with-zutool--title">
						with <span class="is-large">頭痛ーる</span>で<br>気圧と上手に付き合って<br>快適な毎日へ♪
					</p>
					<i class="growthhack-f__with-zutool--img sprite_stress_2 with-zutool" />
				</div>
				<div class="about-rightnow__login">
					<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="about-rightnow__login--button">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
					<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="about-rightnow__login--button">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
				</div>
				<div class="stresscheck-res__outro--box">
					<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=stresscheckDiagnosis` } })" class="stresscheck-res__outro--back-stress-button">ストレスチェックにもどる</a>
					<a @click="$router.push({ name: 'Cpsite', query: { id: `${sugotokuContentsId}` } })" class="stresscheck-res__outro--back-top-button">TOPにもどる</a>
				</div>
			</section>
			<!-- /アウトロ -->
		</article>
	</div>
</div>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'

// Vuex
import { mapGetters } from 'vuex'

export default {
	name: 'stresscheckDiagnosisRes',
	components: {
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			backendUrl: cmnConst.BACKEND_URL
		}
	},
	props: {
		result: {
			type: String,
			default: ''
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid']),

		// 結果の出しわけ
		computedResultItem () {
			const resultNumber = !this.result ? -1 : Number(this.result)
			const resultLevel = resultNumber >= 0 && resultNumber <= 2 ? 1
				: resultNumber >= 3 && resultNumber <= 5 ? 2
					: resultNumber >= 6 && resultNumber <= 8 ? 3
						: resultNumber >= 9 && resultNumber <= 10 ? 4
							: resultNumber >= 11 && resultNumber <= 12 ? 5
								: 0
			const resultItem = {
				level: resultLevel,
				src: resultLevel !== 0 ? require(`@/assets/img/stresscheck-diagnosis/level_${resultLevel}.png`) : '',
				title: '',
				text: ''
			}
			switch (resultLevel) {
				case 1:
					resultItem.title = '安心'
					resultItem.text = 'ストレスとうまく付き合えているようですね。<br>これからも、ご自身の体調に向き合いながら、ストレスを溜めすぎない生活をしていってください。「最近イライラしやすいな」「眠れない日が多いな」など、気になったときには、またこのチェックをやってみてくださいね。'
					break
				case 2:
					resultItem.title = '軽いストレス'
					resultItem.text = '少しずつストレスを抱え込みつつある状態のようです。<br>こまめにストレス解消をやってみてくださいね。自分でできるメンタル強化法としては、ウォーキングや水泳といったリズム運動がおすすめです。笑うこと、泣くことも、実は腹筋を使ったメンタル強化運動です。ご自身の感情を発散できる時間を作ってみて下さいね。'
					break
				case 3:
					resultItem.title = 'やや強いストレス'
					resultItem.text = 'この頃、がんばりすぎていませんか？<br>ストレスを溜め込まない生活を意識しましょう。自分でできるメンタル強化法として、「太陽の光を浴びる」がおすすめ。忙しい日々をお過ごしかもしれませんが、毎朝の習慣にしてみてくださいね。'
					break
				case 4:
					resultItem.title = '強いストレス'
					resultItem.text = '強いストレスの影響で、いくつかの症状を辛いと感じているのではないでしょうか？<br>この状況を続けていってしまうと危険です。仕事や私生活を振り返ってストレス要因を探すなど、対処を試みてください。'
					break
				case 5:
					resultItem.title = '非常に強いストレス'
					resultItem.text = '仕事や日常生活に影響が出ているのではないでしょうか？<br>休養が必要です。そして、早めに専門の病院を受診することをおすすめします。どうか、無理しないでくださいね。'
					break
				default:
					resultItem.title = '結果の取得に失敗しました。'
					resultItem.text = ''
			}
			return resultItem
		}
	},
	mounted () {
	},
	beforeDestroy () {
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

// NOTE: このページ限定の変数（デザインシステム外のため他での利用禁止）
$spacing-12: 0.750rem;
$spacing-14: 0.875rem;
$spacing-22: 1.375rem;
$spacing-24: 1.5rem;
$spacing-28: 1.75rem;
$spacing-30: 1.875rem;
$spacing-38: 2.375rem;
$spacing-40: 2.5rem;
$font-size-13: 0.812rem;
$font-size-15: 0.937rem;
$stresscheck-background: #D9EBEA;
$supervisor-background: #F5F5F5;
$stress-primary: #EF7A76;
$stress-back-background: #F1605C;
$stress-back-box-shadow: #C23F3C;
$result-bottom-border-color: #ABABAB;

.result-container {
	background-color: $stresscheck-background;
}

.outro-container {
	background-color: $background-primary;
}

.stresscheck-res {
	padding: 0 0 $spacing-24;

	&__result {
		margin: $spacing-20 auto 0;
		padding: 0 0 $spacing-22;
		font-size: $font-size-15;
		color: $text-primary;
		position: relative;

		&--level {
			margin: 0 $spacing-14;
			padding: $spacing-18 0 $spacing-12 0;
			text-align: center;
			border-radius: 39px;
			border: 3px solid $stress-primary;
			background-color: $text-seconday;
			box-sizing: border-box;

			&::before {
				content: '結果レベル';
				width: 133px;
				height: 27px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: -12px;
				left: 50%;
				transform: translateX(-50%);
				border-radius: 46px;
				background-color: $stress-primary;
				font-size: $font-size-16;
				font-family: $lp-family-secondary;
				color: $text-seconday;
			}

			p {
				margin: 0;
				font-size: $spacing-28;
				font-family: $lp-family-tertiary;
				color: $stress-primary;
			}

			.is-failed { font-size: $font-size-20; }
		}

		&--img {
			width: 100%;
			margin: $spacing-10 0 0 0;
		}

		&--text {
			font-family: $lp-family-primary;
			font-size: $font-size-16;
			margin: $spacing-10 $spacing-14 0;
			padding-bottom: $spacing-22;
			text-align: justify;
			border-bottom: 1px solid $result-bottom-border-color;
		}
	}

	&__supervisor {
		margin: 0 $spacing-14 0;
		padding: $spacing-20;
		box-sizing: border-box;
		font-family: $lp-family-primary;
		background-color: $supervisor-background;
		border-radius: 4px;

		&--title {
			display: flex;
			align-items: center;
			margin: 0;

			& > span {
				display: block;
				margin-left: $spacing-10;
				font-size: $font-size-12;
				font-weight: normal;
			}
			.is-large { font-size: $font-size-14; }
		}

		&--text {
			margin: $spacing-14 0 0;
			font-size: $font-size-13;
			text-align: justify;
		}
	}

	&__outro {
		margin: 0;
		padding-top: $spacing-24;
		background-color: $background-primary;

		&--title {
			margin: 0;
			padding: 0 $spacing-14;
			font-family: $lp-family-primary;
			font-size: $font-size-15;
			font-weight: normal;
		}

		.growthhack-f__caution { margin: $spacing-20 auto 0; }

		.about-rightnow__login { padding-top: 0; }

		&--box {
			width: 92.5334%;
			margin: $spacing-28 auto 0;
			border-top: 1px solid $line-primary;

			& > a {
				display: block;
				width: 86.4553%;
				margin: $spacing-40 auto 0;
			}
		}

		&--back-stress-button {
			display: block;
			width: 80%;
			margin: $spacing-38 auto 0;
			padding: $spacing-10 0;
			box-sizing: border-box;
			background: $stress-back-background;
			box-shadow: 0 3px $stress-back-box-shadow;
			border-radius: 6px;
			text-align: center;
			color: $text-seconday;
			font-size: $font-size-24;
			pointer-events: auto;

			@media (max-width: (374px)) { font-size: $font-size-16; }
			@media (max-width: (330px)) { font-size: $font-size-14; }
		}

		&--back-top-button {
			max-width: 300px;
			width: 100%;
			display: block;
			appearance: none;
			color: $text-seconday;
			font-size: $font-size-24;
			background: $button-primary;
			border-radius: 27px;
			margin: $spacing-30 auto 0;
			padding: 10px 10px 8px;
			text-align: center;
			text-decoration: none;
			box-sizing: border-box;
		}
	}

	.sprite_stress_1 {
		background-image: url(~@/assets/img/stresscheck-diagnosis/sprite_inp.png);
		background-size: (260px / 2) (372px / 2);
		background-repeat: no-repeat;
		display: block;

		&.logo {
			width: (180px / 2);
			height: (52px / 2);
			background-position: 0 (-320px / 2);
		}
	}

	.sprite_stress_2 {
		background-image: url(~@/assets/img/stresscheck-diagnosis/sprite_res.png);
		background-size: 112px 205px;
		background-repeat: no-repeat;
		display: block;

		&.supervisor {
			width: 72px;
			height: 72px;
			background-position: 0 0;
		}

		&.with-zutool {
			width: 112px;
			height: 133px;
			background-position: 0 -72px;
		}
	}
}
</style>
